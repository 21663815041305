<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card>
          <h1 class="mb-0">{{ account_name }} Analytics</h1>
        </b-card>
      </b-col>
    </b-row>


    <b-row class="justify-content-center">
      <b-col class="col-md-6 col-lg-4" :key="app.id" v-for="app in apps">
        <b-card>
          <div class="border rounded mb-2 pb-1">
            <h4 class="mt-2 ml-1 d-flex align-items-center">
              <img :src="app.icon" alt="" height="40" />
              <span class="ml-1">
                {{ app.title }}
              </span>
            </h4>
            <hr />
            <div class="d-flex justify-content-between px-1 pb-1 pt-0">
              <span>Rate: </span>
              <b>{{ app.rate }} / 5</b>
            </div>
            <div class="d-flex justify-content-between px-1 pb-1 pt-0">
              <span>Installs: </span>
              <b>{{ app.installs }}</b>
            </div>
            <div class="d-flex justify-content-between px-1 pt-0">
              <span>Reviews: </span>
              <b>{{ app.reviews }}</b>
            </div>
            <hr />
            <div class="row justify-content-between px-1">
              <div class="col-6">
                <a @click="redirectTo(app.id)" class="
                      btn btn-block btn-sm btn-outline-primary
                      text-primary
                      d-flex
                      justify-content-center
                      align-items-center
                    ">
                  Info &nbsp;
                  <feather-icon icon="InfoIcon" size="20" />
                </a>
              </div>
              <div class="col-6">
                <a :href="`https://play.google.com/store/apps/details?id=${app.appId}`" target="_blank" class="
                      btn btn-block btn-sm btn-outline-primary
                      d-flex
                      justify-content-center
                      align-items-center
                    ">
                  Visit &nbsp;
                  <feather-icon icon="RotateCwIcon" size="20" />
                </a>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "@axios";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardGroup,
  BCardText,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BCardText,
    BForm,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VBTooltip,
    BButton,
    BContainer,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      apps: [],
      account_id: null,
      account_name: null,
    };
  },
  mounted() {
    this.account_id = router.history.current.params.account_id;
    this.account_name = router.history.current.params.account_name;
    this.getApps();
  },
  created() { },
  methods: {
    getApps() {

      axios
        .post(`/get-apps-by-account-id`, {
          user_token: JSON.parse(localStorage.getItem("accessToken")),
          account_id: this.account_id

        })
        .then((response) => response.data)
        .then((data) => {
          this.apps = data.apps;
        })
        .catch(console.error);
    },
    redirectTo(app) {
      this.$router.push({
        name: "apps-info",
        params: { id: app, from: 'db' },
      });
    },
  },
};
</script>
